<div mat-dialog-title class="aca-rule-set-picker__header">
  <div class="aca-rule-set-picker__header__title">
    {{ 'ACA_FOLDER_RULES.LINK_RULES_DIALOG.TITLE' | translate }}
  </div>
  <button mat-icon-button mat-dialog-close class="aca-rule-set-picker__header__close" tabindex="-1">
    <mat-icon class="aca-rule-set-picker__header__close__icon">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="aca-rule-set-picker__content">
  <adf-content-node-selector-panel
    class="aca-rule-set-picker__content__node-selector"
    [currentFolderId]="defaultNodeId"
    (select)="onNodeSelect($event)"
    (folderLoaded)="setFolderLoading(false)"
    (navigationChange)="setFolderLoading(true)"
    (siteChange)="setFolderLoading(true)">
  </adf-content-node-selector-panel>

  <div class="aca-rule-set-picker__content__rule-list" [ngClass]="{ 'aca-justify': rulesLoading$ | async }">
    <ng-container *ngIf="rulesLoading$ | async; else rulesLoaded">
      <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </ng-container>

    <ng-template #rulesLoaded>
      <ng-container *ngIf="hasOwnedRules; else noOwnedRules">
        <div class="aca-rule-set-picker__content__rule-list__header">
          {{ 'ACA_FOLDER_RULES.LINK_RULES_DIALOG.LIST_OF_RULES_TO_LINK' | translate }}
        </div>

        <aca-rule-list-item
          *ngFor="let rule of (mainRuleSet$ | async).rules"
          [rule]="rule">
        </aca-rule-list-item>
      </ng-container>

      <ng-template #noOwnedRules>
        <adf-empty-content
          icon="library_books"
          [title]="'ACA_FOLDER_RULES.LINK_RULES_DIALOG.EMPTY_RULES_LIST.TITLE' | translate"
          [subtitle]="'ACA_FOLDER_RULES.LINK_RULES_DIALOG.EMPTY_RULES_LIST.SUBTITLE' | translate">
        </adf-empty-content>
      </ng-template>
    </ng-template>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="aca-rule-set-picker__footer">
  <button mat-flat-button mat-dialog-close>
    {{ 'ACA_FOLDER_RULES.LINK_RULES_DIALOG.CANCEL' | translate }}
  </button>

  <button
    mat-flat-button color="primary"
    [disabled]="!hasOwnedRules || isBusy"
    (click)="onSubmit()">
    {{ 'ACA_FOLDER_RULES.LINK_RULES_DIALOG.SUBMIT' | translate }}
  </button>
</mat-dialog-actions>

<ng-container *ngFor="let item of items">

  <aca-rule-list-item
    *ngIf="item.type === 'rule'; else loadMoreRules"
    matRipple matRippleColor="hsla(0,0%,0%,0.05)"
    tabindex="0"
    [rule]="item.rule"
    [isSelected]="item.rule.id === this.selectedRule?.id"
    [showEnabledToggle]="showEnabledToggles"
    (click)="onRuleClicked(item.rule)"
    (enabledChanged)="onEnabledChanged(item.rule, $event)">
  </aca-rule-list-item>

  <ng-template #loadMoreRules>
    <div
      *ngIf="item.type === 'load-more-rules'; else loadMoreRuleSets"
      tabindex="0"
      class="aca-rule-list-grouping__non-rule-item aca-load-more"
      matRipple matRippleColor="hsla(0,0%,0%,0.05)"
      (click)="onClickLoadMoreRules(item.ruleSet)"
      (keyup.enter)="onClickLoadMoreRules(item.ruleSet)">
      {{ 'ACA_FOLDER_RULES.RULE_LIST.LOAD_MORE_RULES' | translate }}
    </div>
  </ng-template>

  <ng-template #loadMoreRuleSets>
    <div
      *ngIf="item.type === 'load-more-rule-sets'; else loadingRules"
      tabindex="0"
      class="aca-rule-list-grouping__non-rule-item aca-load-more"
      matRipple matRippleColor="hsla(0,0%,0%,0.05)"
      (click)="onClickLoadMoreRuleSets()"
      (keyup.enter)="onClickLoadMoreRuleSets()">
      {{ 'ACA_FOLDER_RULES.RULE_LIST.LOAD_MORE_RULE_SETS' | translate }}
    </div>
  </ng-template>

  <ng-template #loadingRules>
    <div
      tabindex="0"
      class="aca-rule-list-grouping__non-rule-item"
      matRipple matRippleColor="hsla(0,0%,0%,0.05)">
      <mat-spinner mode="indeterminate" [diameter]="16" class="aca-spinner"></mat-spinner>
      {{ 'ACA_FOLDER_RULES.RULE_LIST.LOADING_RULES' | translate }}
    </div>
  </ng-template>

</ng-container>

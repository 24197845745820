<aca-page-layout>
  <div class="aca-page-layout-header">
    <button mat-icon-button [routerLink]="landingPage">
      <mat-icon class="app-profile-icon">arrow_back</mat-icon>
    </button>
    <h1>{{ 'APP.BROWSE.ABOUT.TITLE' | translate }}</h1>
  </div>

  <div class="aca-page-layout-content aca-scrollable">
    <adf-about>
      <adf-about-panel *ngIf="dev" [label]="'ABOUT.SERVER_SETTINGS.TITLE' | translate">
        <ng-template>
            <adf-about-server-settings></adf-about-server-settings>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel [label]="'ABOUT.REPOSITORY' | translate" *ngIf="repository">
        <ng-template>
            <adf-about-repository-info [data]="repository"></adf-about-repository-info>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="dev" [label]="'ABOUT.PACKAGES.TITLE' | translate">
        <ng-template>
            <adf-about-package-list [dependencies]="packageJson?.dependencies"></adf-about-package-list>
        </ng-template>
      </adf-about-panel>

      <adf-about-panel *ngIf="extensions$ | async as extensions" [label]="'ABOUT.PLUGINS.TITLE' | translate">
        <ng-template>
            <adf-about-extension-list [data]="extensions"></adf-about-extension-list>
        </ng-template>
      </adf-about-panel>
    </adf-about>
  </div>
</aca-page-layout>

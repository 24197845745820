<div class="aca-custom-name-column">
  <div class="aca-name-column-container">
    <span
      role="link"
      tabindex="0"
      [attr.aria-label]="
        (isFile ? 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FILE_LINK_ARIA_LABEL' : 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FOLDER_LINK_ARIA_LABEL')
          | translate : { name: displayText$ | async }
      "
      class="adf-datatable-cell-value"
      title="{{ node | adfNodeNameTooltip }}"
      (click)="onLinkClick($event)"
      (keyup.enter)="onLinkClick($event)"
    >
      {{ displayText$ | async }}
    </span>

    <ng-container *ngIf="isFile && isFileWriteLocked">
      <aca-locked-by [node]="context.row.node"></aca-locked-by>
    </ng-container>
  </div>
  <div class="aca-name-column-badges">
    <ng-container *ngFor="let badge of badges">
      <adf-dynamic-component *ngIf="badge.component; else iconBadge" [id]="badge.component" [data]="{ node }"></adf-dynamic-component>
      <ng-template #iconBadge>
        <adf-icon class="adf-datatable-cell-badge" [title]="badge.tooltip | translate" [value]="badge.icon" (click)="onBadgeClick(badge)"></adf-icon>
      </ng-template>
    </ng-container>
  </div>
</div>

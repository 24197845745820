<div class="app-search-container app-search-menu-trigger"
  [matMenuTriggerFor]="searchOptionsMenu"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="syncInputValues()"
>

  <button mat-icon-button class="app-search-button" (click)="searchByOption()" [title]="'SEARCH.BUTTON.TOOLTIP' | translate">
    <mat-icon [attr.aria-label]="'SEARCH.BUTTON.ARIA-LABEL' | translate">search</mat-icon>
  </button>
  <mat-form-field class="app-input-form-field" [floatLabel]="'never'">
    <input
      matInput
      [attr.aria-label]="'SEARCH.INPUT.ARIA-LABEL' | translate"
      class="app-input-form-field-input"
      [type]="'text'"
      [disabled]="true"
      [value]="searchedWord"
      [placeholder]="'SEARCH.INPUT.PLACEHOLDER' | translate"
    />

    <div matSuffix class="app-suffix-search-icon-wrapper">
      <mat-icon>arrow_drop_down</mat-icon>
    </div>

    <button mat-icon-button matSuffix class="app-suffix-search-icon-wrapper app-close-button" (click)="exitSearch()">
      <mat-icon class="app-close-icon">close</mat-icon>
    </button>
  </mat-form-field>
</div>

<mat-menu #searchOptionsMenu="matMenu" [overlapTrigger]="true" class="app-search-options-menu">
  <div (keydown.tab)="$event.stopPropagation()" (keydown.shift.tab)="$event.stopPropagation()" tabindex=0>
    <div cdkTrapFocus>
      <app-search-input-control
        #searchInputControl
        (click)="$event.stopPropagation()"
        (submit)="onSearchSubmit($event)"
        (searchChange)="onSearchChange($event)"
      >
      </app-search-input-control>
      <mat-hint *ngIf="hasLibrariesConstraint" class="app-search-hint">{{ 'SEARCH.INPUT.HINT' | translate }}</mat-hint>

      <div id="search-options" class="app-search-options">
        <mat-checkbox
          class="app-search-options-checkbox"
          *ngFor="let option of searchOptions"
          id="{{ option.id }}"
          [(ngModel)]="option.value"
          [disabled]="option.shouldDisable()"
          (change)="searchByOption()"
          (click)="$event.stopPropagation()"
        >
          {{ option.key | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-menu>
